body {
	background: #FFF;
}
.wrapper {
	width: 100%;
	max-width: 390px;
}
.auth-header {
	position: absolute;
	margin: 0 auto;
	top: 0;
	left: 0;
	right: 0;
	text-align: center;
	padding: 20px 0;
	
	@media(max-width: 767px) {
		padding: 0;
		position: relative;
		margin: 0 0 30px;
		
		img {
			height: 40px;
		}
	}
}
.auth-main {
	text-align: center;
	
	.title {
		text-transform: uppercase;
		font-weight: 700;
		margin: 0 0 15px;
		font-size: 2.25rem;
		
		&.t-res {
			font-size: 1.5rem;
		}
	}
	
	form {
		$placeholderColor: #C2C2C2;
		$placeholderOpacity: 1;

		::-webkit-input-placeholder {
			color: $placeholderColor;
			opacity: $placeholderOpacity;
		}

		::-moz-placeholder {
			color: $placeholderColor;
			opacity: $placeholderOpacity;
		}

		:-moz-placeholder {
			color: $placeholderColor;
			opacity: $placeholderOpacity;
		}

		:-ms-input-placeholder {
			color: $placeholderColor;
			opacity: $placeholderOpacity;
		}
		label {
			display: block;
			text-align: left;
			color: #5E5E5E;
			font-size: 14px;
			font-weight: 700;
			margin: 0 0 3px;
		}
		input {
			/*background: #FFF;
			border: 2px solid #F0F0F0;
			border-radius: 5px;
			height: 34px;
			padding: 0 20px;
			width: 100%;
			font-size: 14px;
			font-weight: 700;*/
			background: #fff;
			border: 2px solid #f0f0f0;
			border-radius: 5px;
			height: 34px;
			padding: 0 10px;
			width: 100%;
			font-size: 14px;
			font-weight: 500;
		}
		.cl-btn {
			background: #0D1A34;
			min-height: 46px;
			color: #FFF;
			font-size: 14px;
			font-weight: 700;
			
			&:hover,
			&:active,
			&:focus {
				background: darken(#0D1A34,10%);
				color: #FFF;
			}
		}
	}
	
	.note {
		text-align: center;
		margin: 13px 0 20px;
		font-size: 14px;
		font-weight: 700;
		color: #5E5E5E;
		
		p {
			margin: 0 0 7px;
			
			&:last-child {
				margin: 0;
			}
		}
		
		a {
			color: #6A4DC0;
			text-decoration: none;
			
			&:hover,
			&:active,
			&:focus {
				color: darken(#6A4DC0,10%);
			}
		}
	}
	
	.warnings {
		background: #F6F6F6;
		border-radius: 5px;
		text-align: center;
		padding: 15px 20px;
		color: #5E5E5E;
		font-size: 12px;
		font-weight: 700;
	}
	
	@media(max-width: 767px) {
		padding: 0 10px;
	}
}