.enter-inner {
  min-height: 100vh;
  display: flex;
  color: #45405B;

  &.reset-inner {
    flex-direction: column;
    text-align: center;
    padding: 40px;
    background: #F9F9FC;

    .logo {
      margin: 0 0 30px 0;

      img {
        width: 210px;
      }
    }

    .forma {
      .title {
        font-size: 24px;
        text-transform: uppercase;
      }
    }

    .enter-content {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.verified-inner {
    flex-direction: column;
    text-align: center;
    padding: 40px;
    background: #F9F9FC;

    .logo {
      margin: 0 0 30px 0;

      img {
        width: 210px;
      }
    }

    .for-title {
      .icon {
        margin: 0 0 15px 0;

        img {
          width: 65px;

          @media(max-width: 767px) {
            width: 45px;
          }
        }
      }
    }

    .forma {
      .title {
        font-size: 24px;
        text-transform: uppercase;
      }
    }

    .enter-content {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.verification-inner {
    flex-direction: column;
    text-align: center;
    padding: 40px;
    background: #F9F9FC;

    .logo {
      margin: 0 0 30px 0;

      img {
        width: 210px;
      }
    }

    .for-title {
      .icon {
        margin: 0 0 15px 0;

        img {
          width: 45px;
        }
      }
    }

    .forma {
      .title {
        font-size: 24px;
        text-transform: uppercase;
      }
    }

    .enter-content {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .enter-info {
    flex-shrink: 0;
    width: 25%;
    min-width: 300px;
    background: #fff;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #DBDBDB;

    .logo {
      margin: 0 0 10px 0;
      padding: 25px 35px;
      text-align: center;

      img {
        width: 235px;
      }
    }

    .enter-languages {
      border-top: 1px solid #DBDBDB;
      border-bottom: 1px solid #DBDBDB;
      padding: 20px 25px;
      background: #FAFCFD;

      .icon {
        img {
          max-width: 40px;
        }
      }

      .links {
        display: flex;
        justify-content: center;
        max-width: 320px;
        margin: auto;

        a {
          background: #F0F6F8;
          border: 1px solid #D3D7DB;
          height: 30px;
          width: 100%;
          flex-grow: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px 10px;
          text-decoration: none;
          cursor: pointer!important;

          &:hover {
            background: lighten(#EBEBEB, 5%);
          }

          &:not(:last-of-type) {
            margin-right: 14px;
          }

          &.active {
            background: #DCE1E3;
          }
        }
      }

      @media(min-width: 768px) and (max-width: 1199px) {
        padding: 20px;
      }
    }

    .for-text {
      text-align: left!important;
      font-weight: 700;
      padding: 30px 40px;

      .tit {
        font-size: 24px;
      }

      .text {
        margin-top: 19px;
        font-size: 14px;
      }

      @media(min-width: 768px) and (max-width: 1199px) {
        padding: 45px 20px;
      }
    }

    .enter-bottom {
      margin-top: auto;
    }

    .exit-link {
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      padding: 15px 0;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media(max-width: 767px) {
      display: none;
    }
  }

  .enter-main {
    padding: 30px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    text-align: center;
    background: #F9F9FC;
  }

  .forma {
    font-size: 13px;
    font-weight: 700;
    max-width: 595px;
    width: 100%;
    max-width: 490px;
    margin: auto;
    padding: 20px;
    background: #FFFFFF;
    border: 1px solid #DCE1E6;
    border-radius: 1px;

    .title {
      font-size: 32px;
      margin: 0 0 20px 0;
      font-weight: 700;
    }

    .link {
      margin: 15px 0 0 0;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    @media(max-width: 767px) {
      padding: 15px;
    }
  }

  form {
    width: 100%;
    color: #5E5E5E;
    text-align: left;
    font-weight: 700;

    .inputblock {
      margin: 0 0 8px 0;

      label {
        margin: 0 0 8px 0;
      }
    }

    textarea {
      height: 106px;
    }

    .cl-btn {
      width: 100%;
      min-height: 45px;
      border-radius: 5px;
      font-weight: 700;
    }
  }


}