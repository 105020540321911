@mixin flex-section-center($height){
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: $height;
}

@mixin flex-button($height){
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: $height;
	text-decoration: none;
}

@mixin flex-square($size){
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: $size;
	height: $size;
}
@mixin placeholder($color) {
    &::-webkit-input-placeholder {
        color: $color;
    }

    &::-moz-placeholder {
        color: $color;
    }

    &:-moz-placeholder {
        color: $color;
    }

    &:-ms-input-placeholder {
        color: $color;
    }
}