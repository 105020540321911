@import "utilites","mixin";
body {
	font-family: $font-family-base;
	font-size: $font-size-root;
	line-height: $font-line-height;
	color: #000;
	font-weight: $headings-font-weight;
	margin: 0;
	background: #FFF;
}

.wrapper {
	overflow: hidden;

	&.full {
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-direction: column;
		min-height: 100vh;

		&>header,
		&>footer,
		&>main {
			width: 100%;
		}

		&>main {
			flex-grow: 1;

			section {
				width: 100%;
			}
		}

		&>section {
			flex-grow: 1;
		}
	}
}

.container {
	position: relative;
}

a {
	color: inherit;
	text-decoration: underline;

	&:hover,
	&:active,
	&:focus {
		color: inherit;
		text-decoration: none;
	}
}

a,
input,
button {
	transition: all 0.3s ease-in-out;

	&:active,
	&:focus {
		outline: none !important;
	}
}

p {}

.min-title,
h3 {}

.title,
h2 {}

p,
.title,
.min-title,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	position: relative;
	z-index: 2;
}

a,
button {
	&.cl-btn {
		display: -webkit-inline-flex;
		display: -moz-inline-flex;
		display: -ms-inline-flex;
		display: -o-inline-flex;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		text-decoration: none;
		color: #fff;
		padding: 5px 15px;
		min-height: 28px;
		cursor: pointer;
		border: none;
		position: relative;
		z-index: 2;
		background: $color-blue-d;
		border: 1px solid $color-blue-d;
		border-radius: 0;
		border-radius: 2px;

		&:hover {
			background: none;
			color: $color-blue-d;
		}

		&.bd-btn {
			background: none;
			color: $color-blue-d;

			&:hover {
				background: $color-blue-d;
				color: #fff
			}
		}

		&.bd-btn-gray {
			border-color: #CCCCCC;
			color: #000;
			background: none;

			&:hover {
				background: #E9F0F6;
			}
		}

		&.gradient {
			&:hover {
				opacity: 0.7;
			}
		}

		&.gradient-blue {
			background: linear-gradient(259.75deg, #CFD217 -11.47%, #285CAB 27.14%, #1F7C71 68.34%, #155976 112.1%);
			border: none;
			color: #fff;
		}

		&.gradient-orange {
			background: linear-gradient(259.75deg, #CFD217 -11.47%, #BE9324 27.14%, #D88131 68.34%, #D27927 112.1%);
			border: none;
			color: #fff;
		}
	}
}

form {

	.form-control,
	.form-select {
		@include placeholder(#C2C2C2);
		border-radius: 5px;
		border: 2px solid #F0F0F0;
		color: #000;
		width: 100%;
		outline: none;
		font-size: 14px;
		height: 34px;
		background-color: transparent;
		box-shadow: none !important;

		&:focus {
			border-color: #3d3d3d;
		}

		&input {
			height: 34px;
			padding: 0 10px;

			/* Chrome, Safari, Edge, Opera */
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			/* Firefox */
			&[type="number"] {
				-moz-appearance: textfield;
			}
		}
	}

	.form-select {
		height: 34px;
		padding: 0 10px;
	}

	textarea.form-control {
		padding: 10px;
		resize: none;
	}

	label {
		font-weight: 700;
		margin: 0 0 10px 0;
	}

	@media(max-width: 767px) {}
}

.mobile-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	display: none;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	background: $color-blue-d;
	padding: 10px;
	height: 45px;
	z-index: 100;

	@media(max-width: 1199px) {
		display: flex;
	}

	.humb {
		width: 40px;
		padding: 8px 7px;
		background: transparent;
		cursor: pointer;
		transition: 0.3s;

		&.active {
			background: none;

			div {
				background: #fff;

				&:not(:last-of-type) {
					margin-bottom: 0px;
				}
			}

			.dv-2 {
				opacity: 0;
			}

			.dv-1 {
				transform: rotate(-45deg);
			}

			.dv-3 {
				transform: rotate(45deg) translate(-3px, -3px);
			}
		}

		div {
			height: 2px;
			background: #fff;
			transition: 0.3s;

			&:not(:last-of-type) {
				margin-bottom: 5px;
			}
		}
	}
}

.field-line {
	display: flex;

	&.copy-field {

		.cl-btn {
			font-size: 7px;

			.icon {
				transition: 0.3s;
			}

			&:hover {
				.icon {
					filter: invert(1);
				}
			}
		}
	}

	input,
	textarea {
		background: #FFFFFF;
		border: 1px solid #D3D3D3;
		color: #000;
		outline: none;
		width: 100%;
		min-height: 32px;
		flex-grow: 1;
		padding: 8px;

		&:focus {
			border-color: $color-blue-d;
		}
	}

	textarea {
		min-height: 70px;
		resize: none;
	}

	.cl-btn {
		width: 70px;
		font-size: 10px;
		flex-shrink: 0;
	}
}

.edit-field {
	position: relative;

	.edit-button {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		border: none;
		background: none;
		cursor: pointer;
		padding: 0;

		&:hover {
			opacity: 0.5;
		}
	}
}

.page-404 {
	min-height: 100vh;

	.info {
		text-align: center;
	}

	.t1 {
		font-size: 10em;
	}

	.t2 {
		font-size: 1.4em;
		margin: 0 0 20px;
	}

	.t3 {
		font-size: 0.9em;
	}
}

.clock {
	line-height: 1;

	//Обычный с точками
	&.simple-dots {
		.countdown-row {
			display: -webkit-inline-flex;
			display: -moz-inline-flex;
			display: -ms-inline-flex;
			display: -o-inline-flex;
			display: inline-flex;
			text-align: center;

			.countdown-section {
				position: relative;
				width: 2em;
				padding: 0.2em;
				text-align: center;
				background-color: #ffffff;
				box-shadow: 0px 10px 35px 11px rgba(0, 0, 0, 0.07);
				border-radius: 4px;

				&:not(:last-of-type) {
					margin-right: 0.9em;

					&:before {
						position: absolute;
						content: ":";
						right: -0.55em;
						top: 50%;
						transform: translateY(-50%);
						font-weight: inherit;
						font-size: inherit;
						line-height: inherit;
						color: inherit;
						filter: invert(1);
					}
				}

				span {
					display: block;
					color: inherit;
					font-weight: inherit;
					line-height: inherit;

					&.countdown-amount {
						font-size: inherit;
						font-weight: inherit;
					}

					&.countdown-period {
						font-size: 0.4em;
						margin: 5px 0 0 0;
						font-weight: 400;
					}
				}
			}
		}
	}
}

.scroll-wrapper {
	overflow-x: auto;

	@media(min-width: 1200px) {

		/* Works on Firefox */
		& {
			scrollbar-width: thin;
			scrollbar-color: $color-blue-d $color-gray-l;
		}

		/* Works on Chrome, Edge, and Safari */
		&::-webkit-scrollbar {
			width: 20px;
		}

		&::-webkit-scrollbar-track {
			background: $color-gray-l;
		}

		&::-webkit-scrollbar-thumb {
			background-color: $color-blue-d;
			border-radius: 20px;
			border: 2px solid $color-gray-l;
		}
	}

}

.bd-block {
	background: #FDFDFD;
	border: 1px solid #DCDCDC;
	border-radius: 2px;
	padding: 8px;
}

.bd-gray {
	border-color: #E8E8E8;
}

.status-circle {
	width: 11px;
	height: 11px;
	border-radius: 50%;
	display: inline-block;

	&.red {
		background: $color-red;
	}

	&.green {
		background: #07C51A;
	}

	&.yellow {
		background: #E9ED33;
	}
}

ul {
	ul {
		list-style-type: disc;
	}
}

.info-message {
	font-size: 14px;
}

.modem-block {
	max-width: 330px;
	padding: 20px;
	margin: 10px auto 0;

	.name {
		font-size: 16px;
		margin: 0 0 5px 0;
		font-weight: 700;
	}

	.m-block {
		padding: 15px;
	}

	.for-title {
		margin: 0 0 15px 0;
		text-align: center;
	}

	.tit {
		font-size: 16px;
	}

	.modem-header {
		margin: 0 0 10px 0;

		.h-info {
			.live-ip {
				display: flex;
				justify-content: flex-end;
				text-align: right;
				align-items: center;
				flex-grow: 1;
			}
		}
	}

	.modem-main {
		border: 1px solid $color-gray;

		.m-block {
			&:not(:last-of-type) {
				border-bottom: 1px solid $color-gray;
			}
		}
	}

	.ip-rotation {
		.cl-btn {
			width: 145px;
			font-size: 14px;
			min-height: 43px;
			border-radius: 5px;
		}
	}

	@media(min-width: 1200px) {
		width: 330px;
	}
}

.proxies-table {
	font-size: 12px;
	display: inline-block;

	.pt-line {
		display: flex;
		align-items: center;
		padding: 10px 20px;
		text-align: center;

		&>div {
			padding: 0 3px;
			flex-shrink: 0;
			word-break: break-word;
		}

		input {
			height: 32px !important;
		}

		.cl-btn {
			min-height: 32px;
		}
	}

	.pt-head {
		font-weight: 700;
	}

	.pt-row {
		border-top: 1px solid $color-gray;

		.pt-status {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			text-align: right;
		}
	}

	.pt-country {
		width: 120px;
	}

	.pt-id {
		width: 110px;
	}

	.pt-connections {
		width: 135px;
	}

	.pt-http {
		width: 105px;
	}

	.pt-sockss {
		width: 115px;
	}

	.pt-username {
		width: 135px;
	}

	.pt-password {
		width: 140px;
	}

	.pt-rotateip {
		width: 110px;

		.cl-btn {
			width: 100%;
		}
	}

	.pt-resetlink {
		width: 155px;

		.cl-btn {
			width: 40px;
		}
	}

	.pt-interval {
		width: 215px;
	}

	.pt-whitelist {
		width: 100px;

		.cl-btn {
			padding: 3px;
			width: 100%;
			font-size: 10px;
		}
	}

	.pt-status {
		width: 165px;
		font-size: 12px;
	}

	.export-link {
		color: #BBBBBB;
		font-size: 12px;

		a {
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.proxies-info {
	max-width: 465px;
	width: 100%;

	.bd-block {
		padding: 15px;
	}

	.tit {
		font-weight: 700;
		font-size: 14px;
	}

	.proxy-count {
		margin-top: 8px;
		color: #7E7E7E;
		font-size: 10px;
		text-align: center;
	}

	.proxy-blocks {

		.bd-block {
			&:not(:last-of-type) {
				margin-bottom: 16px;
			}
		}
	}

	.country-choose {
		.tit {
			margin: 0 0 13px 0;
		}
		.col-3{
			//min-width: 120px;
		}
		.cl-btn {
			display: block;
			font-size: 14px;
			min-height: 58px;
			padding: 5px;
			text-align: center;

			&.active {
				background: $color-blue-l;
			}

			.name {
				margin: 0 0 5px 0;
				word-wrap: break-word;
			}

			.flag {
				width: 35px;
				margin: auto;

				img {
					max-width: 100%;
				}
			}

			@media(max-width: 767px) {
				font-size: 12px;
			}
		}
	}

	.carrier-choose,
	.region-choose {

		.cl-btn {
			width: 100%;
			font-size: 14px;
			padding: 5px;
			word-wrap: break-word;

			&.active {
				background: $color-blue-l;
			}

			@media(max-width: 767px) {
				font-size: 12px;
			}
		}
	}

	.tariff-info {
		margin-top: 10px;

		.bd-block {
			&:not(:last-of-type) {
				margin-bottom: 16px;
			}
		}

		.flag {
			width: 21px;

			img {
				max-width: 100%;
			}
		}
	}

	.tariff {
		transition: 0.3s;
		cursor: pointer;

		&:hover,
		&.active {
			background: #F7F7FF;
		}

		.t-head {
			padding: 0 0 20px 0;
			margin: 0 0 17px 0;
			border-bottom: 1px solid $color-gray;
		}

		.name {
			font-weight: 700;
			font-size: 18px;
			display: flex;
			align-items: center;

			a {
				line-height: 1;
				display: block;
				margin: 0 0 0 10px;
			}
		}

		.location {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			.flag {
				margin-left: 7px;
				flex-shrink: 0;
			}
		}

		.price {
			font-weight: 700;
			font-size: 40px;
			line-height: 1;
		}
	}
}

.for-payment {
	max-width: 385px;
	width: 100%;

	.payment-info {
		padding: 15px;

		.p-head {
			.tit {
				font-weight: 700;
				font-size: 18px;
			}
		}

		.p-row {
			&:not(:last-of-type) {
				border-bottom: 1px solid $color-gray;
				padding-bottom: 12px;
				margin-bottom: 12px;
			}
		}

		.p-quantity {
			input {
				background: #FDFDFD;
				border: 1px solid #B9B9B9;
				border-radius: 2px;
				width: 50px;
				height: 26px;
				text-align: center;
				font-weight: 700;
			}
		}

		.p-tariff {
			.price {
				font-size: 14px;
			}
		}

		.p-summ {
			.summ {
				font-weight: 700;
				font-size: 24px;
			}
		}
	}

	.buttons {
		margin: 18px 0 0 0;
	}
}

.subscribtions-table {
	display: inline-block;

	.s-line {
		display: flex;
		align-items: center;
		padding: 10px 20px;

		&:nth-of-type(even) {
			background: #F8F8F8;
		}

		&>div {
			padding: 0 3px;
			flex-shrink: 0;
			word-break: break-word;
		}

		input {
			height: 26px !important;
		}

		.cl-btn {
			min-height: 26px;
			font-size: 10px;
			padding: 5px;
		}
	}

	.s-head {
		font-weight: 700;
		font-size: 14px;
	}

	.s-row {
		.s-renew {
			display: flex;
			align-items: center;
		}
	}

	.s-active {
		width: 180px;
	}

	.s-time {
		width: 80px;
	}

	.s-renew {
		width: 180px;

		.remove {
			cursor: pointer;
			line-height: 0;
			transition: 0.3s;

			&:hover {
				opacity: 0.5;
			}
		}
	}

	.s-subscribe {
		width: 150px;

		.cl-btn {
			width: 100%;

			&.active {
				border-color: transparent;
			}
		}
	}
}

.login-information {
	max-width: 440px;
	width: 100%;

	.tit {
		font-size: 18px;
		font-weight: 700;
	}

	.tt {
		font-size: 14px;
		font-weight: 700;
	}

	.bd-block {
		padding: 15px;
	}

	.login-block {
		&:not(:last-of-type) {
			margin-bottom: 15px;
		}
	}

	.list-block {
		.l-row {
			border-bottom: 1px solid $color-gray;
			padding: 5px 0;

			.l-block {
				display: flex;
				align-items: center;
			}
		}

		.add-new-device {
			margin-top: 10px;
		}

		.remove-link {
			color: #B3B3B3;
			text-decoration: none;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.crypto-addresses {
	max-width: 570px;
	width: 100%;

	.tit {
		font-weight: 700;
		font-size: 18px;
	}

	.bd-block {
		padding: 5px 15px;
	}

	.a-row {
		padding: 10px 0;

		&:not(:last-of-type) {
			border-bottom: 1px solid $color-gray;
		}

		.name {
			font-weight: 700;
			width: 100px;
			word-break: break-word;
		}

		.c-address {
			text-align: right;
			word-break: break-word;
		}

		.copy-link {
			width: 45px;
			text-align: right;
			display: block;
			text-decoration: none;
			color: #676767;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.tooltip {
	opacity: 1 !important;

	.tooltip-arrow {
		display: none;
	}

	.tooltip-inner {
		max-width: 350px;
		width: 100%;
		background: #fff;
		color: #000;
		border: 1px solid #BEBEBE;
		border-radius: 20px;
		padding: 10px;
	}

	@media(max-width: 767px) {
		.tooltip-inner {
			font-size: 12px;
			max-width: 250px;
		}
	}
}

.modal {
	.modal-dialog {
		max-width: 380px;

		.modal-content {
			border: none;
			background: none;
			box-shadow: 0px 0px 20px rgba(69, 69, 69, 0.5);
			border-radius: 10px;
			background: #E9E9E9;

			.btn-close {
				position: absolute;
				right: 10px;
				top: 10px;
				opacity: 1;
				z-index: 5;
				font-size: 10px;
				cursor: pointer;
			}

			.cancel {
				position: absolute;
				right: 10px;
				top: 10px;
				display: inline-block;
				background: none;
				font-size: 10px;
			}

			.modal-block {
				font-size: 14px;

				.m-head {
					background: #fff;
					border-radius: 10px 10px 25px 25px;
					padding: 0 15px 15px 15px;
					text-align: center;

					.icon {
						margin: 0 0 10px 0;

						img {
							margin-top: -40px;
						}
					}

					.tit {
						font-weight: 700;
						font-size: 18px;
						margin: 0 0 15px 0;
					}

					.cl-btn {
						width: 100%;
						border-radius: 50px;
						min-height: 31px;
						padding: 5px;

						&:not(.active) {
							background: none;
							color: #525252;
							border: none;

							&:hover {
								color: #000;
							}
						}
					}
				}

				.m-main {
					padding: 15px 20px 20px 20px;


					.card-head {
						display: flex;
						align-items: center;
						margin: 0 0 10px 0;
						font-weight: 700;

						.icon {
							flex-shrink: 0;
							margin-right: 6px;
						}
					}

					.address {
						background: #FFFFFF;
						border: 1px solid #D5D5D5;
						border-radius: 10px;
						padding: 5px 10px;
						text-align: left;
						word-wrap: break-word;
					}

					form {
						.form-control {
							background-color: #FFFFFF;
							border: 1px solid #D5D5D5;
							border-radius: 10px;
						}

						.cl-btn {
							min-height: 41px;
							margin: 11px 0 0 0;
							border-radius: 10px;
							font-weight: 700;
							width: 100%;
						}
					}
				}
			}
		}
	}
}

.copy {
	cursor: pointer;

	&:hover {
		color: $color-purple;
	}
}