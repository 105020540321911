.cmodal {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  //max-height: 0;
  transition: 0.2s all;
  background: rgba(0,0,0,.5);

  display: flex;
  align-items: center;
  justify-content: center;

  &.show {
    opacity: 1;
    z-index: 999;
    //max-height: 100vh;
  }

  .mod-close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 9;
  }

  .mod-inner {
    position: relative;
    max-width: 380px;
    width: 100%;
  }

  .mod-body {
    background: #E9E9E9;
    border-radius: 2px;
    border: solid #DCDCDC 1px;
  }

  .mod-pay-head {
    background: #FFF;
    border-radius: 1px 1px 25px 25px;
    padding: 0 25px 25px;
    text-align: center;

    .icn-pay {
      margin: -40px 0 10px;
    }

    .mod-pay-title {
      font-size: 18px;
      font-weight: 700;
      //margin: 0 0 20px;
      margin: 0 0 0px;
    }

    .pay-choice {
      .cl-btn {
        min-height: 31px;
        border-radius: 25px;
        background: #FFF;
        color: #525252;
        font-size: 14px;
        font-weight: 400;
        padding: 0;

        &:hover,
        &:active,
        &:focus,
        &.active {
          background: #303E61;
          color: #FFF;
        }
      }
      form {
        .form-control {
          background-color: #FFFFFF;
          border: 1px solid #D5D5D5;
          border-radius: 10px;
        }

        .cl-btn {
          min-height: 41px;
          margin: 11px 0 0 0;
          border-radius: 10px;
          font-weight: 700;
          width: 100%;
        }
      }
    }
  }
  .mod-pay-body {
    padding: 15px 25px 25px;

    .mod-pay-subtitle {
      font-size: 14px;
      font-weight: 700;
      margin: 0 0 10px;
      display: flex;
      align-items: center;
      button{
        font-family: "Noto Sans KR", sans-serif;
        font-size: 12px;
        color: rgba(131, 131, 131, 1);
      }
    }
    .pay-form {
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: 0.2s all;

      &.active {
        opacity: 1;
        max-height: 700px;
      }
    }
    form {
      input {
        height: 30px;
        text-align: center;
        color: #000;
        border: 1px solid #D4D4D4;
        border-radius: 10px;
        width: 100%;
        font-size: 12px;
      }
      .controls {
        .cl-btn {
          min-height: 41px;
          margin: 11px 0 0 0;
          border-radius: 10px;
          font-weight: 700;
          width: 100%;
        }
      }
    }
  }
}