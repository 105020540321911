@import "utilites","mixin";
.enter-inner1 {
    min-height: 100vh;
    display: flex;

    &.reset-inner {
        flex-direction: column;
        text-align: center;
        padding: 40px;

        .logo {
            margin: 0 0 30px 0;

            img {
                width: 210px;
            }
        }

        .forma {
            .title {
                font-size: 24px;
                text-transform: uppercase;
            }
        }

        .enter-content {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .enter-info {
        flex-shrink: 0;
        width: 25%;
        padding: 0 0px 35px 0px;
        min-width: 300px;
        background: #F4F4F4;
        display: flex;
        flex-direction: column;

        .logo {
            margin: 0 0 20px 0;
            padding: 35px;
            text-align: center;
            border-bottom: 1px solid #DBDBDB;

            img {
                width: 235px;
            }

            @media(min-width: 768px) and (max-width: 1199px) {
                padding: 25px;
            }
        }

        .enter-languages {
            border-bottom: 1px solid #DBDBDB;
            padding: 25px 25px 45px 25px;

            .links {
                display: flex;
                justify-content: center;

                a {
                    background: #EBEBEB;
                    border: 1px solid #DBDBDB;
                    height: 30px;
                    width: 100%;
                    flex-grow: 1;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 2px 10px;
                    text-decoration: none;
                    cursor: pointer!important;

                    &:hover {
                        background: lighten(#EBEBEB, 5%);
                    }

                    &:not(:last-of-type) {
                        margin-right: 14px;
                    }

                    &.active {
                        background: #DBDBDB;
                    }
                }
            }

            @media(min-width: 768px) and (max-width: 1199px) {
                padding: 20px;
            }
        }

        .for-text {
            font-weight: 700;
            padding: 70px 40px;

            .tit {
                font-size: 24px;
            }

            .text {
                margin-top: 19px;
                font-size: 14px;
            }

            @media(min-width: 768px) and (max-width: 1199px) {
                padding: 45px 20px;
            }
        }

        .exit-link {
            color: #B3BAFF;
            font-size: 14px;
            font-weight: 700;
            text-align: center;
            margin-top: auto;

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        @media(max-width: 767px) {
            display: none;
        }
    }

    .enter-main {
        padding: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        text-align: center;
    }

    .forma {
        font-size: 14px;
        font-weight: 700;
        max-width: 595px;
        width: 100%;
        max-width: 395px;
        margin: auto;

        .title {
            font-size: 36px;
            margin: 0 0 20px 0;
            font-weight: 700;
        }

        .link {
            margin: 15px 0 0 0;

            a {
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    form {
        width: 100%;
        color: #5E5E5E;
        text-align: left;
        font-weight: 700;

        .inputblock {
            margin: 0 0 8px 0;

            label {
                margin: 0 0 8px 0;
            }
        }

        textarea {
            height: 106px;
        }

        .cl-btn {
            width: 100%;
            min-height: 45px;
            border-radius: 5px;
            font-weight: 700;
        }
    }


}

.dashboard-inner {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background: #FBFBFB;

    @media(max-width: 1199px) {
        padding-top: 45px;
    }

    &.bgs-blue-l {
        background: $color-blue-l;
    }

    .dashboard-header {
        height: 72px;
        border-bottom: 1px solid #E8E8E8;
        background: $color-gray-l;
        display: flex;

        .logo {
            width: 187px;
            flex-shrink: 0;
            border-right: 1px solid #E8E8E8;
            display: flex;
            align-items: center;
            justify-content: center;

            @media(max-width: 1199px) {
                display: none;
            }
        }

        .header-blocks {
            overflow: hidden;
            width: calc(100% - 172px);
            display: flex;
            text-align: center;

            .hd-block {
                display: flex;
                align-items: center;
                padding: 15px;
            }

            @media(max-width: 1199px) {
                width: 100%;

                .hd-block {
                    padding: 6px;
                }
            }
        }

        .h-blocks {
            display: flex;
        }

        .h-left {
            @media(max-width: 767px) {
                display: none;
            }
        }

        .h-right {
            flex-grow: 1;
            justify-content: flex-end;

            .hd-block {
                width: 100%;
            }
        }

        .layout-change {
            .cl-btn {
                background: none;
                border: 1px solid $color-gray;
                color: #000;
                padding: 5px;
                min-width: 50px;

                &:hover,
                &.active {
                    background: $color-gray;
                }
            }
        }

        .search-block {
            form {
                flex-shrink: 0;
                margin-left: 10px;
                transition: 0.3s;
                position: relative;

                input {
                    background: #FFFFFF;
                    border: 1px solid #E1E1E1;
                    width: 80px;
                    height: 26px;
                    transition: 0.3s;
                    padding: 0 20px 0 5px;

                    &:focus {
                        width: 130px;
                        border-color: #3d3d3d;
                    }
                }

                button {
                    position: absolute;
                    right: 0;
                    padding: 5px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                    border: none;
                    background: none;

                    &:hover {
                        transform: translateY(-50%) scale(1.05);
                        opacity: 0.6;
                    }
                }
            }
        }

        .pending {
            max-width: 168px;
            font-size: 10px;

            .status-circle {
                position: absolute;
                right: 0;
                top: 0;
                transform: translate(50%, -50%);
                width: 11px;
                height: 11px;
                border-radius: 50%;
            }

            @media(max-width: 767px) {
                font-size: 8px;
                padding: 9px;
            }
        }

        .notification {

            a {
                color: $color-blue;
            }

            @media(max-width: 767px) {
                font-size: 8px;
            }
        }

        .notification-renew {
            max-width: 205px;
        }

        .notification-ring {
            max-width: 235px;
            font-size: 10px;
        }

        .notification-contact {
            max-width: 280px;
            font-size: 10px;

            .cl-btn {
                background: #F3F3F3;
                color: #000;

                &:hover {
                    background: darken(#F3F3F3, 15%);
                }
            }
        }

        .balance {
            max-width: 180px;
            justify-content: center;

            .tit {
                font-size: 14px;
                margin: 0 0.9em 0 0;
            }

            .balance-info {
                .summ {
                    background: $color-gray;
                    padding: 5px;
                    border-radius: 50px;
                    margin: 0 0 3px 0;
                }

                a {
                    color: $color-blue;
                    font-size: 10px;
                }
            }

            @media(max-width: 767px) {
                .tit {
                    font-size: 12px;
                }
            }
        }
    }

    .dashboard-menu {
        width: 187px;
        flex-shrink: 0;
        border-right: 1px solid #E8E8E8;
        background: $color-gray-l;
        display: flex;
        flex-direction: column;

        @media(max-width: 1199px) {
            position: fixed;
            left: 0;
            top: 0;
            height: 100%;
            overflow-y: auto;
            background: $color-blue-d;
            padding: 60px 0 0px 0;
            z-index: 99;
            color: #fff;
            transition: 0.2s ease-in-out;

            &:not(.active) {
                transform: translateX(-100%);
                opacity: 0;
                visibility: hidden;
            }

            .icon {
                filter: invert(1);
            }

            .m-block {
                .links {
                    a {

                        &.active,
                        &:hover {
                            background: #fff;
                            color: #000;

                            .icon {
                                filter: none;
                            }
                        }
                    }
                }
            }
        }

        .tit {
            text-align: center;
            font-weight: 700;
            text-transform: uppercase;
            margin: 0 0 15px 0;
            color: #545363;

            @media(max-width: 767px) {
                color: #fff;
            }
        }

        .m-blocks {
            margin: 0 0 30px 0;
        }

        .m-block {
            padding: 12px 6px;

            .links {
                a {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    border-radius: 10px;
                    padding: 12px;

                    &.active {
                        background: $color-gray;
                    }

                    .icon {
                        flex-shrink: 0;
                        margin-right: 10px;
                    }

                    &:not(.active):hover {
                        background: lighten($color-gray, 10%);
                    }
                }
            }
        }

        .menu-bottom {
            margin-top: auto;
        }

        .languages {
            text-align: center;
            padding: 15px 0;

            .link {
                &:not(:last-of-type) {
                    margin-bottom: 15px;
                }

                a {
                    display: block;
                    text-decoration: none;
                    cursor: pointer!important;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .logout-link {

            text-align: center;
            font-size: 10px;

            a {
                text-decoration: none;
                display: block;
                padding: 15px 5px;
                cursor: pointer!important;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .dashboard-main {
        display: flex;
        flex-grow: 1;

        .dashboard-content {
            padding: 11px;
            width: calc(100% - 172px);

            @media(max-width: 1199px) {
                width: 100%;
            }
        }
    }
}