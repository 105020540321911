.languages-auth {
  text-align: center;
  padding: 15px 0;
.links{
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

    &:not(:last-of-type) {
      margin-bottom: 15px;
    }

    a {
      display: block;
      text-decoration: none;
      cursor: pointer!important;

      &:hover {
        text-decoration: underline;
        cursor: pointer!important;
      }
    }
  }
  .active{
    a{
      color: red;
    }

}

}