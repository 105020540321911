.notifications{
//  position: absolute;
 // width: 60px;
 // top: 18px;
//  right: 120px;
  line-height: 42px;
  z-index: 20;
  margin-left: 20px;
  padding-right: 20px;

  i{
    font-size: 24px;
    color: #0b2e13;
  }
  .badge{
    font-size: 10px;
    top: -15px;
    left: -12px;
  }
  .dropdown-menu {
    height: 500px;
    overflow-x: auto;
    background: #fff;
    border-radius: 4px;
    width: auto;
    margin: -0px auto;
    padding: 0px;
    position: absolute;
    top: 100%;
    left: -220px;
    box-shadow: 0 4px 5px 0 rgba(60,75,100,.14), 0 1px 10px 0 rgba(60,75,100,.12), 0 2px 4px -1px rgba(60,75,100,.2);

    .dropdown-item{
      padding: 8px 20px 8px 8px;
      margin: 0;
      border-bottom: 1px solid #f6f6f6;
      font-size: 14px;
      display: flex;
      align-items: center;
      i{
        min-width: 35px;
        display: inline-block;
        margin-right: 10px;
        text-align: center;
        color: gold;
      }
      .badge{
        font-size: 10px;
        background-color: #00B7FF;
        color: white;
        min-height: 16px;
      }
    }
  }

}



