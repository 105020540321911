/* ******************* <Variables> ********************** */
// Colors
$color-primary: #0d6efd;
$color-secondary: #6c757d;
$color-success: #198754;
$color-info: #0dcaf0;
$color-warning: #ffc107;
$color-danger: #dc3545;
$color-light: #f8f9fa;
$color-dark: #545363;
$color-red: #FE2C55;
$color-blue: #7B86BD;
$color-purple: #4f3487;
$color-blue-d: #303E61;
$color-blue-l: #F6F8FA;
$color-gray-l: #FAFAFA;
$color-gray: #E8E8E8;

$colors: ("blue-l": $color-blue-l, "blue": $color-blue, "purple": $color-purple, "blue-d": $color-blue-d, "red": $color-red, 'gray-l': $color-gray-l, 'gray': $color-gray);

@each $name, $value in $colors {
  .color-#{$name} {
    color: $value;
  }
}

@each $name, $value in $colors {
	.bgs-#{$name} {
	  background: $value;
	}
  }

// Typography
$font-family-base: 'Noto Sans',system-ui,-apple-system,sans-serif;
$font-size-root: 12px;
$font-size-base: 1rem;
$font-line-height: 1.3;

$headings-font-family: $font-family-base;
$headings-font-weight: 400;
$headings-line-height: 1.15;

// Grid Breakpoints
$grid-xs: 0;
$grid-sm: 576px;
$grid-md: 768px;
$grid-lg: 992px;
$grid-xl: 1200px;
$grid-xxl: 1400px;
/* ******************* </Variables> ********************** */

/* ******************* <Sizes> *********************** */
@for $i from 0 through 100 {
	.fs-#{$i} {
		font-size: #{$i}px;
	}
}

@media (min-width: $grid-sm) {
	@for $i from 0 through 100 {
		.fs-sm-#{$i} {
			font-size: #{$i}px;
		}
	}
}

@media (min-width: $grid-md) {
	@for $i from 0 through 100 {
		.fs-md-#{$i} {
			font-size: #{$i}px;
		}
	}
}

@media (min-width: $grid-lg) {
	@for $i from 0 through 100 {
		.fs-lg-#{$i} {
			font-size: #{$i}px;
		}
	}
}

@media (min-width: $grid-xl) {
	@for $i from 0 through 100 {
		.fs-xl-#{$i} {
			font-size: #{$i}px;
		}
	}
}

@media (min-width: $grid-xxl) {
	@for $i from 0 through 100 {
		.fs-xxl-#{$i} {
			font-size: #{$i}px;
		}
	}
}

/* ******************* </Sizes> ********************** */

.fw-thin {
	font-weight: 100
}

.fw-elight {
	font-weight: 200
}

.fw-light {
	font-weight: 300
}

.fw-normal {
	font-weight: 400
}

.fw-medium {
	font-weight: 500
}

.fw-semi {
	font-weight: 600
}

.fw-bold {
	font-weight: 700
}

.fw-heavy {
	font-weight: 800
}

.fw-black {
	font-weight: 900
}

.tt-upper {
	text-transform: uppercase
}

.tt-none {
	text-transform: none
}

.tt-lower {
	text-transform: lowercase
}

.td-under {
	text-decoration: underline
}

.strike {
	text-decoration: line-through
}